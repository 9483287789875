<template>
    <el-dialog
        :title="''"
        class="theme-dialog"
        :visible.sync="show"
        width="680px"
        :before-close="close"
    >
        <div class="theme-body">
            <div class="theme-list">
                <div class="title-group">
                    <span></span>
                    <p class="title">{{ title }}</p>
                    <span class="close">
                        <i class="el-icon-close" @click="close"></i>
                    </span>
                </div>
                <div class="theme-main">
                    <el-radio-group v-model="checkedThemeMode" v-removeAriaHidden @input="handleCheckedThemeModeChange">
                        <template
                            v-for="(mode, index) in themeModeList"
                        >
                            <div 
                                class="theme-item" 
                                :key="index"
                                :style="{'border-color': mode.mode === checkedThemeMode ? '#3C7FFF' : '#E7E8EB'}"
                                @click="handlerChangeMode(mode)"
                            >
                                <div 
                                    class="theme-item-typical-example"
                                    :style="{
                                        'background-image': mode.typicalExample
                                    }"
                                >
                                </div>
                                <div class="radio">
                                    <el-radio 
                                        :key="index"
                                        :label="mode.mode"
                                    >{{ mode.name }}</el-radio>
                                </div>
                            </div>
                        </template>
                    </el-radio-group>
                </div>
            </div>
            <div class="theme-btn">
                <el-button class="btn" @click="handleSave" v-loading="btnLoading">立即体验</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
    import themeOptions from '@/assets/data/theme_options.js';
    import { setUserThemeConfig } from "@/services";
    import { changeTheme } from '@/config/theme.js';
    import { mapState } from 'vuex';
    export default {
        name: "ThemeDialog",
        props: {
            showTheme: Boolean
        },
        data() {
            return {
                themeOptions,
                title: '选择主题皮肤',
                dialogVisible: false,
                checkedThemeMode: '',
                menuId: '00010002',
                btnLoading: false
            }
        },
        computed: {
            ...mapState({
                mode: state => state.style.mode,
                schoolId: state => state.schoolId,
                userId: state => state.userId,
            }),
            show() {
                return this.showTheme
            },
            themeModeList() {
                return themeOptions.map(item => {
                    return {
                        mode: item.mode,
                        name: item.name,
                        typicalExample: item.typicalExample
                    }
                })
            }
        },
        created() {
            this.init()
        },
        methods: {
            /**
             * @Description: 初始化
             * @Author: 武东超
             * @Date: 2024-08-21 10:07:59
             */            
            init() {
                this.checkedThemeMode = this.mode;
            },
            /**
             * @Description: 选择主题
             * @Author: 武东超
             * @Date: 2024-08-21 09:05:48
             */            
            handleCheckedThemeModeChange() {
                console.log(this.checkedThemeMode,'[checkedThemeMode]')
            },
            /**
             * @Description: 确认主题
             * @Author: 武东超
             * @Date: 2024-08-21 09:06:10
             */            
            async handleSave() {
                try {
                    let config = JSON.parse(JSON.stringify(themeOptions.filter(item => this.checkedThemeMode === item.mode)[0]));
                    delete config.typicalExample;
                    let saveConfig = {
                        schoolId: this.schoolId,
                        menuId: this.menuId,
                        configType: 'themeSkin',
                        config: JSON.stringify(config),
                        userId: this.userId
                    };
                    this.btnLoading = true;
                    let res = await setUserThemeConfig(saveConfig);
                    console.log(res,'主题保存')
                    if (res && res.code === '200') {
                        changeTheme(config.mode, config);
                        this.$store.dispatch('changeTheme',config)
                        this.$message.success('切换成功')
                    } else {
                        this.$message.success('切换失败，稍后再试～')
                    }
                    this.btnLoading = false;
                    this.close();
                } catch(e){

                }
                
            },
            /**
             * @Description: 说明
             * @Author: 作者
             * @Date: 2024-08-23 15:31:17
             */            
            handlerChangeMode(mode) {
                this.checkedThemeMode = mode.mode;
            },
            /**
             * @Description: 关闭
             * @Author: 武东超
             * @Date: 2024-08-21 13:32:10
             */            
            close() {
                this.$emit('handleCloseThemeDialog', {open: false});
            }
        }
    }
</script>
<style lang="scss" scoped>
    .theme-dialog {

        /deep/ .el-dialog .el-dialog__body {
            margin: 0;
            padding: 0;
        }
        /deep/ .el-dialog__header {
            display: none;
        }
        /deep/ .el-dialog__body {
            padding: 0;
        }
        border-radius: 8px;
        .theme-body {
            height: 620px;
            overflow: hidden;
            .theme-list {
                background: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 80%, #C8E1FA 100%);
                height: 520px;
                .title-group {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 9px 24px;
                    .title {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 18px;
                        color: #2B2F33;
                    }
                    .close {
                        .el-icon-close {
                            cursor: pointer;
                            font-size: 18px;
                        }
                    }
                }
                .theme-main {
                    box-sizing: border-box;
                    padding: 55px 44px 0px 44px;
                    height: 455px;
                    overflow: auto;
                    .theme-item {
                        width: 160px;
                        height: 119px;
                        border-radius: 12px;
                        float: left;
                        margin-left: 28px;
                        border: 1px solid #F0F1F2;
                        border-radius: 12px;
                        margin-bottom: 35px;
                        cursor: pointer;
                        .theme-item-typical-example {
                            width: 160px;
                            height: 80px;
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                        }   
                        .radio {
                            box-sizing: border-box;
                            padding: 10px 12px;
                            /deep/ .el-radio__inner{
                                width: 20px;
                                height: 20px;
                            }
                            /deep/ .el-radio__inner::after{
                                width: 8px;
                                height: 8px;
                            }
                            /deep/ .el-radio__label {
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                font-size: 14px;
                                color: #2B2F33;
                            }
                        }
                    }
                    .theme-item:hover {
                        border-style: dashed;
                        border-color: #3C7FFF !important;
                        /deep/ .el-radio__inner {
                            border-color: #3C7FFF !important;
                        }
                    }
                }
            }
            .theme-btn {
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                /deep/ .el-button.el-button--default span {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 15px;
                    color: #FFFFFF !important;
                }
                /deep/ .el-button {
                    height: 36px;
                }
                .btn {
                    background-color: var(--brand-theme);
                    padding: 10px 34px !important;
                }
            }
        }
    }
</style>