<template>
    <div class="tags-view-container">
        <breadcrumbs
            :visitedViews="visitedViews"
            @closeSelectedTag="closeSelectedTag"
            @closeAllTabs="closeAllTabs"
        ></breadcrumbs>
        <el-button class="close-all-tag-btn" type="danger" @click="closeAllTabs">
            <i class="el-icon-switch-button" />
        </el-button>
    </div>
</template>

<script>
import ScrollPane from './ScrollPane'
import Breadcrumbs from './Breadcrumbs.vue'
import RecordsList from '@/libs/pageListOptimal.js';
import { mapMutations } from 'vuex';

export default {
    components: { ScrollPane, Breadcrumbs },
    name: 'TagViews',
    props: {
        headerColor: {
            type: String
        },
        textColor: {
            type: String
        },
    },
    data() {
        return {}
    },
    computed: {
        visitedViews() {
            let visitedViews = [];
            visitedViews = this.$store.state.visitedViews;
            visitedViews.forEach((item) => {
                if (item.path == this.$route.path) {
                    this.$set(item, 'sel', true)
                } else {
                    this.$set(item, 'sel', false)
                }
            })
            return visitedViews;
        },
        routes() {
            return this.$store.state.userRouters
        }
    },
    watch: {
        $route() {
            this.addTags()
            this.moveToCurrentTag()
        }
    },
    mounted() {
        this.initTags()
        this.addTags()
    },
    methods: {
        ...mapMutations(['closeAllTabsMu']),
        isActive(route) {
            return route.path === this.$route.path
        },
        isAffix(tag) {
            return tag.path === '/workbench/cockpit'
        },
        initTags() {
            for (const tag of this.routes) {
                if (tag.name) {
                    this.$store.dispatch('addView', tag)
                }
            }
        },
        addTags() {
            const { name } = this.$route
            if (name) {
                this.$store.dispatch('addView', this.$route)
            }
        },
        moveToCurrentTag() {
            this.$nextTick(() => {
                const tags = this.$refs.tag
                if (tags) {
                    for (const tag of tags) {
                        if (tag.to.path === this.$route.path) {
                                this.$refs.scrollPane.moveToTarget(tag)
                            if (tag.to.fullPath !== this.$route.fullPath) {
                                this.$store.dispatch('updateView', this.$route)
                            }
                            break
                        }
                    }
                }
            })
        },
        closeSelectedTag(view) {
            const recordsList = new RecordsList();
            recordsList.remove(view.name)
            this.$store.dispatch('delView', view).then(({ visitedViews }) => {
                if (this.isActive(view)) {
                    this.toLastView(visitedViews, view)
                }
            })
        },
        toLastView(visitedViews, view) {
            if (visitedViews.length === 0) {
                this.$router.push({
                    name: 'FirstPage'
                })
                return;
            }
            const latestView = visitedViews.slice(-1)[0]
            this.$router.push(latestView.fullPath)
        },
        closeAllTabs () {
            let recordsList = new RecordsList();
            this.$confirm('此操作将关闭所有标签, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                recordsList.clearAllData(this.$route.name)
                this.closeAllTabsMu(this.$route.name)
            }).catch(() => {})
        }
    }
}
</script>

<style lang="scss" scoped>
    .tags-view-container {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 38px;
        background-color: #fff;
        box-shadow: 1px 1px 2px 1px #E1E3E6;
        border-radius: 4px 0px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-right: 10px;
        overflow: hidden;
    }

    ::v-deep .el-button.el-button--danger.close-all-tag-btn {
        padding: 0 10px !important;
        border: 0;
        font-size: 12px;
        background-color: #faebeb !important;

        span {
            color: #f56c6c;
        }

        &:hover {
            background-color: #f56c6c !important;

            span {
                color: #ffffff;
            }
        }
    }

    .el-icon-switch-button {
        font-size: 14px;
        font-weight: 700;
    }
</style>
