<template>
	<el-cascader
		v-model="ceshi.value"
		clearable
		filterable
		:options="ceshi.options"
		:props="{
			multiple: true,
		}"
		:collapse-tags="true"
		placeholder="请选择"
		@change="selectHandle"
	>
		<template slot-scope="{node, data}">
			<span>{{ data.label }}</span>
			<span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
		</template>
	</el-cascader>
</template>
<script>
export default {
    name:'MyElCasader',
	props: {
		cascaderData: {
			type: Object,
		},
	},
	data() {
		return {
			preSelected: [], // 上次选中的数据
			originData: [], //  源数据平铺成一级节点
			ceshi: {
				value: [],
				options: [
					{label: "全选", value: "全选"},
					{label: "层级0", value: "0"},
					{
						label: "层级1",
						value: "1",
						children: [
							{
								label: "层级11",
								value: "11",
								children: [
									{label: "层级111", value: "111"},
									{label: "层级112", value: "112"},
								],
							},
							{
								label: "层级12",
								value: "12",
								children: [{label: "层级121", value: "121"}],
							},
						],
					},
					{
						label: "层级2",
						value: "2",
						children: [
							{label: "层级21", value: "21"},
							{label: "层级22", value: "22"},
						],
					},
					{
						label: "层级3",
						value: "3",
						children: [{label: "层级31", value: "31"}],
					},
				],
			},
		};
	},
	methods: {
		/**
		 * @Description: 判断是否是全选,也就是看已选择的选中中包不包含"全选"
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 陈金伟
		 * @Date: 2023-07-27 09:23:17
		 * @param {*} node
		 */
		judgetAllSelected(node) {
			// 判断是否是全选,也就是看已选择的选中中包不包含"全选"
			let isAllSelected = false;
			for (let i = 0; i < node.length; i++) {
				if (node[i][0] === "全选") {
					isAllSelected = true;
					break;
				}
			}
			return isAllSelected;
		},
		/**
		 * @Description:将所有父子元素全部转为一级元素
		 * @DoWhat: 用来比较 是全选还是非全选
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 陈金伟
		 * @Date: 2023-07-27 09:23:48
		 * @param {*} list
		 * @param {*} parentNode
		 */
		loopFlatData(list = [], parentNode = []) {
			list.length > 0 &&
				list.forEach((e) => {
					let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
					if (e.children && e.children.length > 0) {
						pNode.push(e.value); // 1 11
						this.loopFlatData(e.children, pNode);
					} else {
						if (e.label !== "全选") {
							if (parentNode.length > 0) {
								pNode.push(e.value);
								this.originData.push({...e, parentNode: pNode});
							} else {
								this.originData.push(e);
							}
						}
					}
				});
		},
		/**
		 * @Description:所有节点 设置为 选中状态
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 陈金伟
		 * @Date: 2023-07-27 09:24:29
		 * @param {*} list
		 * @param {*} parentNode
		 */
		loopSelectData(list, parentNode = []) {
			list.length > 0 &&
				list.forEach((e) => {
					let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
					if (e.children && e.children.length > 0) {
						pNode.push(e.value); // 1 11
						this.loopSelectData(e.children, pNode);
					} else {
						if (parentNode.length > 0) {
							this.ceshi.value.push([
								...parentNode,
								e.value,
							]);
						} else {
							this.ceshi.value.push([e.value]);
						}
					}
				});
		},
		/**
		 * @Description: 主要是勾选或取消非“全选”项时，对于全选的逻辑处理
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 陈金伟
		 * @Date: 2023-07-27 09:53:05
		 */
		checkIsAddAllSelected() {
			let list = this.ceshi.options; // 原始数据列表
			if (this.originData.length === 0) {
				this.loopFlatData(list); // 把所有的父子级平铺成一个一级列表
			}
			let origin = this.originData;
			let now = [...this.ceshi.value].filter(
				(item) => item[0] !== "全选",
			);
			if (origin.length > now.length) {
				// 非全选时, 如果有之前选过全选,要把全选过滤掉
				this.ceshi.value = this.ceshi.value.filter(
					(item) => item[0] !== "全选",
				);
			} else {
				// 当所有的数据都选择时, 要自动把全选勾选上
				if (
					this.ceshi.value[0] &&
					this.ceshi.value[0][0] !== "全选"
				) {
					this.ceshi.value = [
						["全选"],
						...this.ceshi.value,
					];
				}
			}
		},
		/**
		 * @Description: 触发点击事件
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 陈金伟
		 * @Date: 2023-07-27 09:29:28
		 * @param {*} node
		 */
		async selectHandle(node = []) {
			this.ceshi.value = [];
			// 选中的数据格式: [['全选'], ['0'], ['1', '11', '111'], ['2', '21'],...]
			let list = this.ceshi.options;
			let current = []; // 获取当前选中的option, 因为element文档中没有获取当前选中的option的方法,所以我通过上一次和本地的选中数据进行对比来获取
			if (node.length >= this.preSelected.length) {
				let keys = this.preSelected.map((item) => JSON.stringify(item));
				current = node.filter(
					(item) => !keys.includes(JSON.stringify(item)),
				);
				console.log("选中某项", current);
			} else {
				// 取消选中
				let keys = node.map((item) => JSON.stringify(item));
				current = this.preSelected.filter(
					(item) => !keys.includes(JSON.stringify(item)),
				);
				console.log("取消选中", current);
			}
			// 根据element的选中数据格式, 每一个选项都是一个列表, 列表第一项为父级value, 第二项为选中的子级value, ...以此类推
			const currentValue = current.length > 0 ? current[0][0] || "" : "";
			if (currentValue === "全选") {
				if (this.judgetAllSelected(node)) {
					this.loopSelectData(list);
				} else {
					this.ceshi.value = [];
				}
			} else {
				this.ceshi.value = node;
			}
			this.checkIsAddAllSelected(); // 主要是勾选或取消非“全选”项时，对于全选的逻辑处理
			this.preSelected = this.ceshi.value; // 保存上一次的选择结果
			this.changeHandle();
		},
		/**
		 * @Description: 处理成自己需要的数据格式, 需要把全选的这一选项过滤掉
		 * @DoWhat: 向父组件 发送数据
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 陈金伟
		 * @Date: 2023-07-27 09:52:11
		 */
		changeHandle() {
			// 这里是处理成自己需要的数据格式, 需要把全选的这一选项过滤掉
			// 原始选择的数据格式[['全选'], ['1', '11'], ['2', '21'],...]
			console.log("changeHandle: ", this.ceshi.value);
			let result1 = this.ceshi.value
				.filter((item) => item[0] !== "全选")
				.map((item) => item[0])
				.flat();
			console.log(result1, "666");
			console.log(this.ceshi.options);
			const result = this.ceshi.options
				.filter((item) => result1.includes(item.value))
				.map((item) => {
					return {
						name: item.label,
						id: item.value,
					};
				});
			console.log(result); // [{label:'1',value:'1'}]
			this.$emit("handleCascader", result1, result);
		},
	},
};
</script>
