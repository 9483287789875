<template>
    <div class="breadcrumbs-wrapper">
        <ul class="breadcrumbs-list">
            <scroll-pane ref="ScrollPane">
                <li
                    ref="itemRef"
                    v-for="(subItem, subIndex) of visitedViews"
                    :key="subIndex"
                    :class="['item', 'item' + subIndex, {current: subItem.sel}]"
                    @click.prevent.stop="changeBtn(subItem, subIndex)"
                >{{ subItem.title }}
                <img
                    class="el-icon-close"
                    :src="crumbClose"
                    alt="close"
                    @click.prevent.stop="closeSelectedTag(subItem)"
                    >
                </li>
            </scroll-pane>
        </ul>
    </div>
</template>
<script>
import {treeFindPathAll} from "@/libs/utils";
import ScrollPane from "@/components/sub/TagViews/ScrollPane.vue";

export default {
    name: "Breadcrumbs",
    components: { ScrollPane },
    data() {
        return {
            show: false,
            // 滚动了几列
            roll: 0,
            widthBox: 0,
            visited_views: [],
            // 右侧下拉相关数据
            options: [],
            value: "",
            //左右按钮禁用状态
            btnDisabledRight: true,
            btnDisabledLeft: true,
            // 所有标签总宽度
            totalWidth: 0,
            // 点击滚动距离
            scrollWidth: 125,
        };
    },
    props: {
        visitedViews: {
            type: Array,
        },
    },
    computed: {
        crumbClose () {
            return require('@/assets/images/crumb-close.png');
        }
    },
    methods: {
        changeBtn(item) {
            console.log("[changeBtn]item:", item)
            this.$router.push({
                path: item.path,
                query: item.query,
                fullPath: item.fullPath,
            });
            // try {
            //     // 修改左侧显示菜单
            //     let allPath = treeFindPathAll(this.routes, "children", (node) => {
            //         return node.name === item.name;
            //     });
            //     if (allPath && allPath[0] && allPath[0][0]) {
            //         this.$eventDispatch("changeModulePage", allPath[0][0]);
            //     }
            // } catch (error) {}
        },

        closeSelectedTag(item) {
            this.$emit("closeSelectedTag", item);
            this.roll -= 1;
        },
    },
};
</script>
<style lang="scss" scoped>
.breadcrumbs-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    overflow: hidden;
    box-shadow: -2px 4px 4px -4px #E1E3E6;

}
.breadcrumbs-list {
    white-space: nowrap;
    overflow-x: auto;
    margin: 0;
    padding: 0;
}
.container {
    display: flex;
    flex: 1;
    height: 32px;
    overflow: hidden;
}
.prev-btn {
    width: 18px;
    background-repeat: no-repeat;
    background-position: 50% 16px;
    margin-right: 10px;

    .el-icon-caret-left {
        color: #ffffff;
        margin-top: 8px;
    }
}
.next-btn {
    width: 18px;
    background-repeat: no-repeat;
    background-position: 50% 16px;
    margin-left: 10px;

    .el-icon-caret-right {
        color: #ffffff;
        margin-top: 8px;
    }
}
.box {
    flex: 1;
    overflow: hidden;
}
.wrap {
    height: 100%;
    display: inline-block;
    white-space: nowrap;
    transition: transform 0.3s;
    box-sizing: border-box;
    overflow-x: auto;
}
.item {
    height: 26px;
    position: relative;
    display: inline-block;
    line-height: 26px;
    border-radius: 4px;
    font-size: 14px;
    color: #737373;
    cursor: pointer;
    padding: 0 10px;
    margin: 6px 10px 6px 0px;
    box-sizing: border-box;
}
.item:hover {
    background-color: #F4F4F4;
    transition: background-color 0.3s;
    .el-icon-close {
        display: inline-block;
    }
}

.item.current {
    background: #EEF0F3;
    color: #737373;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    padding-right: 10px;
    margin-right: 10px;
    .el-icon-close {
        display: inline-block;
    }
}
.el-select /deep/ .el-input--small .el-input__inner {
    height: 32px;
}

.icon-close {
    float: right;
    margin-top: 0px;
    margin-left: 6px;
}
.el-icon-switch-button {
    font-size: 14px;
    font-weight: bold;
}
.el-icon-close {
    position: absolute;
    top: -4px;
    right: -2px;
    display: none;
    z-index: 5;
}
.el-icon-close:hover {
    color: #f57070;
}
.close-all:hover {
    background-color: #f56c6c !important;
    color: #faebeb !important;
}
.allowed {
    cursor: pointer;
    background-color: #3C7FFF;
}
.not-allowed {
    background-color: #c1cedb;
}
</style>
